var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",staticStyle:{"border-radius":"16px"}},[_c('v-toolbar',{staticClass:"mb-1",staticStyle:{"border-top-left-radius":"16 !important","border-top-right-radius":"16 !important"},attrs:{"dark":"","color":"blue darken-3","elevation":"0"}},[_c('span',{staticClass:"font-weight-bold font-size-h4",staticStyle:{"width":"15%"}},[_vm._v(_vm._s(_vm.$t("MF.medicalHistoryLabel")))]),_c('v-spacer',{staticStyle:{"width":"30%"}}),_c('v-select',{staticClass:"mr-4",attrs:{"flat":"","solo-inverted":"","hide-details":"","rounded":"","dense":"","items":_vm.keys,"prepend-inner-icon":"mdi-magnify","label":_vm.$t('MF.sortby'),"loading":_vm.filterloading},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('v-text-field',{attrs:{"clearable":"","flat":"","dense":"","rounded":"","solo-inverted":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":_vm.$t('MF.search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticStyle:{"border-radius":"16px"},attrs:{"items":_vm.historySorted,"loading":_vm.loadingStatus,"locale":this.$root.lang,"headers":_vm.headers,"items-per-page":5,"loading-text":_vm.$t('tLoading'),"search":_vm.search},scopedSlots:_vm._u([{key:"item.history_body",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_c('span',{staticClass:"text-dark-75 font-weight-bold font-size-sm mr-2"},[_vm._v(_vm._s(item.history_body))])])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-dark-75 font-weight-bold font-size-sm mr-2"},[_vm._v(_vm._s(_vm._f("moment")(item.date,"dddd, MMMM Do YYYY")))])]}},{key:"item.history_type.type",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('span',{staticClass:"text-dark-75 font-weight-bold font-size-sm mr-2"},[_vm._v(_vm._s(item.history_type.type))])])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"btn btn-icon btn-warning mx-3",on:{"click":function($event){return _vm.updateRecord(item)}}},[_c('em',{staticClass:"flaticon2-edit"})]),_c('button',{staticClass:"btn btn-icon btn-danger",on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('em',{staticClass:"flaticon2-delete"})])]}}])}),(_vm.openEditDialog)?_c('EditHistory',{attrs:{"dialog":_vm.openEditDialog,"record":_vm.record},on:{"closeDialog":function($event){_vm.openEditDialog = false},"formSubmitted":_vm.formSubmitted}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this File?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }