<template>
  <!-- start for web -->
  <v-card class="elevation-0" style="border-radius:16px;">
    <v-toolbar
      dark
      color="blue darken-3"
      class="mb-1"
      elevation="0"
      style="
        border-top-left-radius: 16 !important;
        border-top-right-radius: 16 !important;
      "
    >
      <span class="font-weight-bold font-size-h4" style="width:15%">{{
        $t("MF.medicalHistoryLabel")
      }}</span>
      <v-spacer style="width:30%"></v-spacer>
      <v-select
        flat
        solo-inverted
        hide-details
        rounded
        dense
        :items="keys"
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.sortby')"
        v-model="sortBy"
        :loading="filterloading"
        class="mr-4"
      ></v-select>

      <v-text-field
        v-model="search"
        clearable
        flat
        dense
        rounded
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-magnify"
        :label="$t('MF.search')"
      ></v-text-field>
    </v-toolbar>
    <v-data-table
      style="border-radius:16px;"
      :items="historySorted"
      :loading="loadingStatus"
      :locale="this.$root.lang"
      :headers="headers"
      :items-per-page="5"
      :loading-text="$t('tLoading')"
      :search="search"
    >
      <template v-slot:item.history_body="{ item }">
        <tr>
          <td colspan="8">
            <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
              item.history_body
            }}</span>
          </td>
        </tr>
      </template>

      <template v-slot:item.date="{ item }">
        <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
          item.date | moment("dddd, MMMM Do YYYY")
        }}</span>
      </template>

      <template v-slot:item.history_type.type="{ item }">
        <tr>
          <td colspan="2">
            <span class="text-dark-75 font-weight-bold font-size-sm mr-2">{{
              item.history_type.type
            }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:item.action="{ item }">
        <button
          class="btn btn-icon btn-warning mx-3"
          @click="updateRecord(item)"
        >
          <em class="flaticon2-edit"></em>
        </button>

        <button class="btn btn-icon btn-danger" @click="deleteItem(item)">
          <em class="flaticon2-delete"></em>
        </button>

        <!-- <a class="btn btn-icon btn-success">
          <em class="flaticon2-medical-records"></em>
        </a> -->
      </template>
    </v-data-table>

    <EditHistory
      @closeDialog="openEditDialog = false"
      @formSubmitted="formSubmitted"
      :dialog="openEditDialog"
      :record="record"
      v-if="openEditDialog"
    />

    <!-- delete item -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this File?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import EditHistory from './addcomp/historyForm';
import EditHistory from "./editcomp/EditHistory";
// import AvatarWithDetails from '@/view/pages/utilComponents/avatar1.vue';
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";

export default {
  name: "History",
  props: ["mfid"],
  components: { EditHistory },
  data() {
    return {
      openEditDialog: false,
      dialogDelete: false,
      record: null,
      search: "",
      sortBy: "",
      error: "",
      keys: ["All"],
      filterloading: true,
      loadingStatus: true,
      history: [],
      //historySorted:[],
      headers: [
        {
          text: this.$t("MF.history.entry"),
          align: "left",
          sortable: true,
          value: "history_body"
        },
        { text: this.$t("MF.date"), value: "date" },
        { text: this.$t("MF.history.type"), value: "history_type.type" },
        {
          text: this.$t("MF.action"),
          value: "action",
          align: "right",
          sortable: false
        }
      ],
      lastSelected: null
    };
  },
  mounted() {
    this.getItems();
    this.getTypes();
    // console.log("hey");
  },
  methods: {
    async getItems() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;

      await ApiService.get(
        "items",
        "md_history?fields=history_type.id,history_type.type,id,date,incident_date,history_body&filter[medical_file]=" +
          this.mfid
      )
        .then(({ data }) => {
          this.history = data.data;
          this.loadingStatus = false;
        })
        .catch(error => {
          this.error = error;
        });
    },
    async getTypes() {
      await ApiService.get("items", "history_type?fields=id,type,translation.*")
        .then(({ data }) => {
          for (var i = 0; i < data.data.length; i++) {
            this.keys.push(data.data[i].type);
          }
          this.filterloading = false;
        })
        .catch(error => {
          this.error = error;
        });
    },
    listItemClicked(value) {
      this.$emit("listQuestionClicked", value);
    },
    entrysum(q) {
      if (q.length < 50) {
        return q;
      } else {
        return q.substring(0, 50) + " ...";
      }
    },

    deleteItem(record) {
      this.record = record;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        this.loadingStatus = true;

        await ApiService.delete("md_history", this.record.id);

        this.loadingStatus = false;
        this.closeDelete();
        this.record = null;
        this.toast(
          "b-toaster-top-center",
          "success",
          "history deleted successfully"
        );
        this.getItems();
      } catch (error) {
        // console.log(error);
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    updateRecord(record) {
      this.record = record;
      this.openEditDialog = true;
    },
    formSubmitted(msg) {
      this.openEditDialog = false;
      this.toast("b-toaster-top-center", "success", msg);
      this.getItems();
    },
    toast(toaster, v, msg, append = false) {
      this.counter++;
      this.$bvToast.toast(msg, {
        title: `Alert`,
        toaster: toaster,
        solid: true,
        variant: v,
        appendToast: append
      });
    }
  },
  computed: {
    historySorted() {
      return this.history.filter(i => {
        return (
          !this.sortBy ||
          i.history_type.type === this.sortBy ||
          this.sortBy == "All"
        );
      });
    }
  }
};
</script>
